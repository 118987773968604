<template>
  <div>
    <!-- Province -->
    <b-form-group label="Provincia" description="Selecciona la provincia de destino">
      <validation-provider #default="{ errors }" name="Provincia" rules="required">
        <b-form-select
          size="sm"
          name="province"
          v-model="item.province"
          :state="errors.length > 0 ? false : null"
          @input="fetchLocations"
          :options="provinces"
          :disabled="disabled"
          value="text"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

    <!-- Localidad -->
    <b-form-group label="Localidad" description="Selecciona la localidad de destino">
      <validation-provider #default="{ errors }" name="Localidad" rules="required">
        <b-form-select
          size="sm"
          name="location"
          v-model="item.location"
          :state="errors.length > 0 ? false : null"
          @input="fetchLocations"
          :options="locations"
          :disabled="disabled"
          value="text"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

    <!-- Calle -->
    <b-form-group label="Calle" description="Ingresa la calle">
      <validation-provider #default="{ errors }" name="Calle" rules="required|max:250">
        <b-form-input
          size="sm"
          name="street"
          v-model="item.street"
          :state="errors.length > 0 ? false : null"
          type="text"
          :disabled="disabled"
        ></b-form-input>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

    <!-- Altura -->
    <b-form-group label="Altura" description="Ingresa la Altura de la calle">
      <validation-provider #default="{ errors }" name="Altura" rules="required|max:15">
        <b-form-input
          size="sm"
          name="height"
          v-model="item.height"
          :state="errors.length > 0 ? false : null"
          type="text"
          :disabled="disabled"
        ></b-form-input>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

    <!-- Piso -->
    <b-form-group
      label="Piso"
      description="Ingresa el piso en caso de ser un apartamento"
    >
      <validation-provider #default="{ errors }" name="Piso" rules="max:120">
        <b-form-input
          size="sm"
          name="floor"
          v-model="item.floor"
          :state="errors.length > 0 ? false : null"
          type="text"
          :disabled="disabled"
        ></b-form-input>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

    <!-- Departamento -->
    <b-form-group label="Departamento" description="Numero del departamento">
      <validation-provider #default="{ errors }" name="Departamento" rules="max:200">
        <b-form-input
          size="sm"
          name="departament"
          v-model="item.departament"
          :state="errors.length > 0 ? false : null"
          type="text"
          :disabled="disabled"
        ></b-form-input>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

    <!-- Codigo Postal -->
    <b-form-group label="Codigo Postal" description="Codigo postal">
      <validation-provider
        #default="{ errors }"
        name="Codigo Postal"
        rules="required|max:30"
      >
        <v-select
          size="sm"
          maxlength="50"
          name="postal_code"
          @input="changePostalCode"
          v-model="item.postal_code"
          :options="postal_codes"
          label="fullText"
          :state="errors.length > 0 ? false : null"
          :disabled="
            client.type !== 'pre-pago' && item.status !== 'PENDIENTE POR PAGO' && disabled
          "
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

    <!-- Nota -->
    <b-form-group
      label="Nota"
      description="Informacion adicional de la direccion de entrega"
    >
      <validation-provider #default="{ errors }" name="Nota" rules="max:400">
        <b-form-textarea
          size="sm"
          maxlength="50"
          type="text"
          xlength="500"
          name="note"
          v-model="item.note"
          :state="errors.length > 0 ? false : null"
          :disabled="disabled"
        ></b-form-textarea>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import LocationService from "@/services/LocationService";
import OperationService from "@/services/OperationService";

export default {
  name: "AddressComponent",
  components: {
    ValidationProvider,
  },
  props: {
    client: Object,
    operation: Object,
    disabled: Boolean,
    addresses: Array,
    provinces: Array,
    item: Object,
  },
  data() {
    return {
      locations: [],
      postal_codes: [],
    };
  },
  mounted() {
    if (this.item.location) {
      this.fetchLocations();
    }

    OperationService.getPostalCodeUser(this.client.id).then(({ data }) => {
      this.postal_codes = data.map((d) => {
        return Object.assign(d, {
          fullText: d,
          code: d,
        });
      });
    });
  },
  methods: {
    changePostalCode() {
      this.$emit("postalCode", this.item.postal_code);
    },
    fetchLocations() {
      if (this.item.province) {
        const data = {
          province_id: this.provinces.find((d) => {
            return this.item.province === d.province.province;
          }).province.id,
        };
        LocationService.locations(data).then(({ data }) => {
          this.locations = data.data.map((d) => d.text);
          const exists = this.locations.find((d) => {
            return d.location === this.item.location;
          });
          if (this.item.location && !exists) {
            this.locations.push(this.item.location);
          }
        });
      }
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
  },
};
</script>
