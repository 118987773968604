var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form-group',{attrs:{"label":"Provincia","description":"Selecciona la provincia de destino"}},[_c('validation-provider',{attrs:{"name":"Provincia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"size":"sm","name":"province","state":errors.length > 0 ? false : null,"options":_vm.provinces,"disabled":_vm.disabled,"value":"text"},on:{"input":_vm.fetchLocations},model:{value:(_vm.item.province),callback:function ($$v) {_vm.$set(_vm.item, "province", $$v)},expression:"item.province"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Localidad","description":"Selecciona la localidad de destino"}},[_c('validation-provider',{attrs:{"name":"Localidad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"size":"sm","name":"location","state":errors.length > 0 ? false : null,"options":_vm.locations,"disabled":_vm.disabled,"value":"text"},on:{"input":_vm.fetchLocations},model:{value:(_vm.item.location),callback:function ($$v) {_vm.$set(_vm.item, "location", $$v)},expression:"item.location"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Calle","description":"Ingresa la calle"}},[_c('validation-provider',{attrs:{"name":"Calle","rules":"required|max:250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","name":"street","state":errors.length > 0 ? false : null,"type":"text","disabled":_vm.disabled},model:{value:(_vm.item.street),callback:function ($$v) {_vm.$set(_vm.item, "street", $$v)},expression:"item.street"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Altura","description":"Ingresa la Altura de la calle"}},[_c('validation-provider',{attrs:{"name":"Altura","rules":"required|max:15"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","name":"height","state":errors.length > 0 ? false : null,"type":"text","disabled":_vm.disabled},model:{value:(_vm.item.height),callback:function ($$v) {_vm.$set(_vm.item, "height", $$v)},expression:"item.height"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Piso","description":"Ingresa el piso en caso de ser un apartamento"}},[_c('validation-provider',{attrs:{"name":"Piso","rules":"max:120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","name":"floor","state":errors.length > 0 ? false : null,"type":"text","disabled":_vm.disabled},model:{value:(_vm.item.floor),callback:function ($$v) {_vm.$set(_vm.item, "floor", $$v)},expression:"item.floor"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Departamento","description":"Numero del departamento"}},[_c('validation-provider',{attrs:{"name":"Departamento","rules":"max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","name":"departament","state":errors.length > 0 ? false : null,"type":"text","disabled":_vm.disabled},model:{value:(_vm.item.departament),callback:function ($$v) {_vm.$set(_vm.item, "departament", $$v)},expression:"item.departament"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Codigo Postal","description":"Codigo postal"}},[_c('validation-provider',{attrs:{"name":"Codigo Postal","rules":"required|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"size":"sm","maxlength":"50","name":"postal_code","options":_vm.postal_codes,"label":"fullText","state":errors.length > 0 ? false : null,"disabled":_vm.client.type !== 'pre-pago' && _vm.item.status !== 'PENDIENTE POR PAGO' && _vm.disabled},on:{"input":_vm.changePostalCode},model:{value:(_vm.item.postal_code),callback:function ($$v) {_vm.$set(_vm.item, "postal_code", $$v)},expression:"item.postal_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Nota","description":"Informacion adicional de la direccion de entrega"}},[_c('validation-provider',{attrs:{"name":"Nota","rules":"max:400"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"size":"sm","maxlength":"50","type":"text","xlength":"500","name":"note","state":errors.length > 0 ? false : null,"disabled":_vm.disabled},model:{value:(_vm.item.note),callback:function ($$v) {_vm.$set(_vm.item, "note", $$v)},expression:"item.note"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }