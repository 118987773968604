<template>
  <div>
    <loading :active.sync="isLoading" :full-page="false"></loading>
    <validation-observer ref="operationForm">
      <b-form @submit="onSubmit" v-if="showForm">
        <h3 class="title">¿A QUIÉN VAMOS A ENTREGAR?</h3>
        <hr />

        <!-- Email       -->
        <b-form-group label="Email" description="Email del destinatario">
          <validation-provider
            #default="{ errors }"
            name="Email"
            rules="required|email"
          >
            <b-form-input
              size="sm"
              type="text"
              label="text"
              name="recipient_email"
              v-model="operation.recipient_email"
              :disabled="formDisabled"
              :state="errors.length > 0 ? false : null"
            ></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Nombre -->
        <b-form-group label="Nombre" description="Nombre del destinatario">
          <validation-provider
            #default="{ errors }"
            name="Nombre"
            rules="required"
          >
            <b-form-input
              size="sm"
              type="text"
              name="recipient_name"
              label="text"
              v-model="operation.recipient_name"
              :disabled="formDisabled"
              :state="errors.length > 0 ? false : null"
            ></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Apellido -->
        <b-form-group label="Apellido" description="Apellido del destinatario">
          <validation-provider
            #default="{ errors }"
            name="Apellido"
            rules="required"
          >
            <b-form-input
              size="sm"
              type="text"
              name="recipient_last_name"
              label="text"
              v-model="operation.recipient_last_name"
              :disabled="formDisabled"
              :state="errors.length > 0 ? false : null"
            ></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Telefono -->
        <b-form-group label="Telefono" description="Telefono del destinatario">
          <validation-provider
            #default="{ errors }"
            name="Telefono"
            rules="required"
          >
            <b-form-input
              size="sm"
              type="text"
              name="recipient_phone"
              label="text"
              v-model="operation.recipient_phone"
              :disabled="formDisabled"
              :state="errors.length > 0 ? false : null"
            ></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <h4 class="title my-2 pt-2">¿A DONDÉ LO LLEVAMOS?</h4>
        <hr />

        <!-- Entrega -->
        <b-form-group
          label="Entrega"
          description="Selecciona el tipo de entrega que deseas"
        >
          <template v-if="client.retirement === 'client & point'">
            <validation-provider
              #default="{ errors }"
              name="Entrega"
              rules="required"
            >
              <b-form-select
                size="sm"
                name="destination"
                v-model="operation.retirement"
                :value="serviceRetirement(client.retirement)"
                :options="[
                  { value: 'client', text: 'DOMICILIO' },
                  { value: 'point', text: 'PUNTO DE RETIRO' },
                ]"
                :state="errors.length > 0 ? false : null"
                :disabled="!isAdmin && $attrs.edit"
                @input="loadTerms"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </template>
          <template v-else>
            <validation-provider #default="{ errors }" name="Descuento">
              <b-form-input
                size="sm"
                type="text"
                name="customer"
                :value="serviceRetirement(client.retirement)"
                :disabled="true"
                :state="errors.length > 0 ? false : null"
              ></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </template>
        </b-form-group>

        <!-- Punto de retiro -->
        <b-form-group
          label="Punto de retiro"
          v-if="
            client.retirement === 'point' || operation.retirement === 'point'
          "
          description="Selecciona el punto de retiro al cual llegara tu envio."
        >
          <validation-provider
            #default="{ errors }"
            name="Punto de retiro"
            rules="required"
          >
            <v-select
              size="sm"
              type="text"
              name="collection_point"
              label="fullText"
              :options="points"
              v-model="operation.collection_point"
              @input="loadTerms"
              :disabled="!isAdmin && $attrs.edit"
              :state="errors.length > 0 ? false : null"
            >
              <template slot="option" slot-scope="option">
                <div class="p-1 m-1 border-primary">
                  <div>
                    <b>Provincia:</b>
                    {{ option.province }}
                  </div>
                  <div>
                    <b>Localidad:</b>
                    {{ option.location }}
                  </div>
                  <div>
                    <b>Calle:</b>
                    {{ option.street }}
                  </div>
                  <div>
                    <b>Altura:</b>
                    {{ option.height }}
                  </div>
                </div>
              </template>
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <div
            v-if="
              operation.collection_point &&
              operation.collection_point.longitude &&
              operation.collection_point.longitude
            "
          >
            <p class="m-2">
              <a :href="url" target="_blank" class="pl-2">
                <feather-icon size="20" icon="MapIcon" class="mr-1" />
                Ver punto en Google
              </a>
            </p>
            <p>Horario</p>
            <table
              class="table table-sm mt-3"
              style="width: 100%"
              v-if="operation.collection_point.schedules.length > 0"
            >
              <tr>
                <th></th>
                <th>Lunes</th>
                <th>Martes</th>
                <th>Miercoles</th>
                <th>Jueves</th>
                <th>Viernes</th>
                <th>Sabado</th>
                <th>Domingo</th>
              </tr>
              <tr>
                <td>Apertura</td>
                <td>
                  {{
                    operation.collection_point.schedules[0].start | hour_format
                  }}
                </td>
                <td>
                  {{
                    operation.collection_point.schedules[1].start | hour_format
                  }}
                </td>
                <td>
                  {{
                    operation.collection_point.schedules[2].start | hour_format
                  }}
                </td>
                <td>
                  {{
                    operation.collection_point.schedules[3].start | hour_format
                  }}
                </td>
                <td>
                  {{
                    operation.collection_point.schedules[4].start | hour_format
                  }}
                </td>
                <td>
                  {{
                    operation.collection_point.schedules[5].start | hour_format
                  }}
                </td>
                <td>
                  {{
                    operation.collection_point.schedules[6].start | hour_format
                  }}
                </td>
              </tr>
              <tr>
                <td>Cierre</td>
                <td>
                  {{
                    operation.collection_point.schedules[0].end | hour_format
                  }}
                </td>
                <td>
                  {{
                    operation.collection_point.schedules[1].end | hour_format
                  }}
                </td>
                <td>
                  {{
                    operation.collection_point.schedules[2].end | hour_format
                  }}
                </td>
                <td>
                  {{
                    operation.collection_point.schedules[3].end | hour_format
                  }}
                </td>
                <td>
                  {{
                    operation.collection_point.schedules[4].end | hour_format
                  }}
                </td>
                <td>
                  {{
                    operation.collection_point.schedules[5].end | hour_format
                  }}
                </td>
                <td>
                  {{
                    operation.collection_point.schedules[6].end | hour_format
                  }}
                </td>
              </tr>
            </table>
            <p>Nota: {{ operation.collection_point.note }}</p>
          </div>
        </b-form-group>

        <div
          class
          v-if="
            client.retirement === 'client' ||
            (operation.retirement && operation.retirement === 'client')
          "
        >
          <AddressComponent
            v-if="
              client.retirement === 'client' ||
              operation.retirement === 'client'
            "
            :provinces="provinces"
            @postalCode="loadTerms"
            :item="operation.retirement_item"
            :client="client"
            :operation="operation"
            :disabled="formDisabled"
          />
        </div>

        <h4 class="title my-2 pt-2">¿QUÉ VAMOS A LLEVAR?</h4>
        <hr />

        <!-- Producto -->
        <b-form-group
          label="Producto"
          description="Descripcion del producto a enviar"
        >
          <validation-provider
            #default="{ errors }"
            name="Producto"
            rules="required"
          >
            <b-form-input
              size="sm"
              type="text"
              name="product"
              v-model="operation.product"
              :disabled="formDisabled"
              label="text"
              :state="errors.length > 0 ? false : null"
            ></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Peso -->
        <b-form-group label="Peso" description="Peso del producto a enviar">
          <validation-provider
            #default="{ errors }"
            name="Peso"
            :rules="{
              required: true,
              min_value: 1,
              // max_value: operation.retirement === 'point' ? 10 : 20,
            }"
          >
            <b-form-input
              size="sm"
              @input="loadTerms"
              name="weight"
              type="number"
              step="any"
              v-model="operation.weight"
              :disabled="formDisabled"
              label="text"
              min="1"
              :state="errors.length > 0 ? false : null"
            ></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <span class="help-input">Minimo 1 KG</span>
        </b-form-group>

        <!-- Cantidad de bultos -->
        <b-form-group
          label="Cantidad de bultos"
          description="Número de paquetes a enviar"
        >
          <validation-provider
            #default="{ errors }"
            name="Cantidad de bultos"
            :rules="{
              required: true,
              min_value: 1,
              max_value: 20,
            }"
          >
            <b-form-input
              size="sm"
              name="quantity_packages"
              type="number"
              v-model="operation.quantity_packages"
              label="text"
              min="1"
              :disabled="formDisabled"
              :state="errors.length > 0 ? false : null"
            ></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Dimensiones -->
        <b-form-group
          label="Dimensiones"
          description="Dimensiones del producto a enviar"
        >
          <validation-provider
            #default="{ errors }"
            name="Dimensiones"
            rules="required"
          >
            <b-form-input
              size="sm"
              type="text"
              name="dimensions"
              maxlength="100"
              placeholder="33x44x55"
              v-model="operation.dimensions"
              :disabled="formDisabled"
              :state="errors.length > 0 ? false : null"
            ></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <b-form-checkbox
            id="is_secured"
            v-model="operation.is_secured"
            :disabled="formDisabled"
            @input="onSecured"
          >
            Quiero asegurar mi envio
          </b-form-checkbox>
        </b-form-group>

        <!-- Valor Declarado -->
        <b-form-group
          label="Valor Declarado"
          :description="
            'Monto declarado del envio, monto maximo ' + maxValue + '$ valido'
          "
          v-if="operation.is_secured"
        >
          <validation-provider
            #default="{ errors }"
            name="Valor Declarado"
            :rules="'required|max_value:' + maxValue"
          >
            <b-form-input
              size="sm"
              type="number"
              step="0.01"
              name="declared_value"
              v-model="operation.declared_value"
              :disabled="formDisabled"
              @input="changeAmount"
              :state="errors.length > 0 ? false : null"
            ></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <div v-if="operation.is_secured">
          <p>Monto a pagar : {{ getPercentageDeclared | currency }}</p>
          <p>
            <a
              v-if="contract"
              :href="contract"
              target="_blank"
              style="
                font-size: 12px;
                color: #ef7736 !important;
                font-weight: bold;
              "
            >
              Condiciones de Contratación Seguros
            </a>
          </p>
        </div>

        <!-- Nota -->
        <b-form-group
          label="Nota"
          description="¿Algo adicional que debamos saber?"
          v-if="operation.is_secured"
        >
          <validation-provider #default="{ errors }" name="Nota">
            <b-form-textarea
              name="note"
              maxlength="700"
              v-model="operation.note"
              :disabled="formDisabled"
              :state="errors.length > 0 ? false : null"
            ></b-form-textarea>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Entrega -->
        <b-form-group
          label="ENTREGA"
          description="¿Qué tán rápido necesitas tu paquete?"
        >
          <b-form-radio-group
            v-model="operation.price"
            :options="terms"
            @input="selectPrice"
            :disabled="formDisabled"
          ></b-form-radio-group>
        </b-form-group>

        <!-- Monto -->
        <b-form-group label="Monto" description="Monto declarado del envio">
          <b-alert show variant="warning" v-if="errorPrice">
            ¡No hay un Precio Disponible!
          </b-alert>

          <b-form-input
            size="sm"
            name="amount"
            :value="operation.amount"
            type="text"
            readonly
            :disabled="formDisabled"
          ></b-form-input>
        </b-form-group>

        <div class="row">
          <div class="col-12" v-if="!formDisabled">
            <b-button
              size="sm"
              ref="submitButton"
              class="mr-1 float-right"
              variant="primary"
              type="submit"
            >
              <feather-icon size="15" icon="CreditCardIcon" class="mr-50" />
              <span style="font-size: 15px"> Guardar </span>
            </b-button>
          </div>

          <div class="col-12" v-else>
            <b-alert show variant="primary" v-if="user.type === 'pre-pago'">
              Para continuar con el proceso de envío, es necesario que la orden
              sea abonada.
            </b-alert>

            <b-button
              size="sm"
              class="mr-1 float-right"
              variant="primary"
              type="button"
              @click="onPay"
              v-if="user.type === 'pre-pago'"
            >
              <feather-icon size="15" icon="CreditCardIcon" class="mr-50" />
              <span style="font-size: 15px"> Pagar </span>
            </b-button>

            <router-link
              class="btn btn-sm btn-primary mr-1 float-right text-white"
              to="/dashboard/operations"
              style="background: #f68d1e; border-color: #f68d1e"
            >
              <feather-icon size="15" icon="ListIcon" class="mr-50" />
              <span style="font-size: 15px">Operaciones</span>
            </router-link>

            <b-button
              v-if="!operation.id"
              size="sm"
              class="mr-1 float-right"
              variant="primary"
              type="reset"
              @click="onClear"
            >
              <feather-icon size="15" icon="PlusIcon" class="mr-50" />
              <span style="font-size: 15px">Nueva</span>
            </b-button>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";

import OperationService from "@/services/OperationService";
import LocationService from "@/services/LocationService";
import UtilService from "@/services/UtilService";
import UserService from "@/services/UserService";
import MercadoPagoService from "@/services/MercadoPagoService";
import SettingService from "@/services/SettingService";
import { mapGetters } from "vuex";

import AddressComponent from "./components/AddressComponent.vue";
import Swal from "sweetalert2";
import AlertService from "@/services/AlertService";

export default {
  name: "OperationForm",
  components: {
    AddressComponent,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    duplicate: {
      type: Boolean,
      default() {
        return false;
      },
    },
    client: Object,
    item: {
      type: Object,
      default() {
        return {
          required,
          email,
          price: null,
          unit: "KG",
          retirement_item: {},
        };
      },
    },
  },
  data() {
    return {
      percentageDeclared: 0,
      baseValue: 0,
      maxValue: 0,
      contract: null,
      isAdmin: UserService.isAdminOrStaff(),
      isLoading: false,
      deposit: null,
      errorPrice: false,
      provinces: [],
      points: [],
      terms: [],
      formDisabled: false,
      operation: Object.assign(this.item, {
        amount: this.$options.filters.currency(
          this.item.amount ? this.item.amount : 0
        ),
      }),
      // user: this.$store.state.user,
      showForm: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
    url: function () {
      return (
        "https://www.google.com/maps?q=" +
        this.operation.collection_point.latitude +
        "," +
        this.operation.collection_point.longitude
      );
    },

    /**
     *
     */
    getPercentageDeclared: function () {
      if (!this.percentageDeclared) {
        return 0;
      }

      if (!this.operation.is_secured) {
        return 0;
      }

      const monto =
        (this.operation.declared_value || 0) * (this.percentageDeclared / 100);

      if (monto < this.baseValue) {
        return this.baseValue;
      } else if (monto > this.maxValue) {
        return this.maxValue;
      }

      return monto;
    },
  },
  methods: {
    /**
     *
     */
    serviceCollection(data) {
      return UtilService.serviceCollection(data);
    },

    /**
     *
     */
    serviceRetirement(data) {
      return UtilService.serviceRetirement(data);
    },

    /**
     *
     */
    collectionStr(item) {
      return item === "client" ? "Cliente" : "Drop-Off";
    },

    /**
     *
     */
    async loadDeposit() {
      const data = await OperationService.deposits();
      this.deposit = data.data;
    },

    /**
     *
     */
    changeAmount() {
      this.operation.amount = this.$options.filters.currency(
        this.operation.price.amount + this.getPercentageDeclared
      );
    },

    /**
     *
     */
    onSecured() {
      this.changeAmount();
    },

    /**
     *
     */
    async loadPoints() {
      const { data } = await OperationService.points({pickup: true});
      this.points = data.data.map((d) => {
        return Object.assign(
          { fullText: `${d.province} ${d.location} ${d.street} ${d.height}` },
          d
        );
      });
    },

    /**
     *
     */
    async loadProvince() {
      const data = await LocationService.provinces();
      this.provinces = data.data.data.map((d) => {
        return {
          text: d.text,
          value: d.text,
          province: {
            id: d.value,
            province: d.text,
          },
        };
      });
    },

    /**
     *
     */
    async loadTerms() {
      try {
        const postal_code = this.getPostalCode();
        const weight = this.operation.weight;
        const unit = "KG"; // this.operation.unit;
        this.operation.price = null;
        this.terms = [];
        if (postal_code && weight) {
          this.isLoading = true;
          const { data } = await LocationService.terms({
            postal_code,
            weight,
            unit,
            user_id: this.client.id,
          });
          this.isLoading = false;
          this.terms = data.data
            .filter((item) => {
              if (this.operation.retirement == "point") {
                return item.point;
              }
              return item.point == false;
            })
            .map((item) => {
              return {
                text: item.term,
                value: item,
              };
            });

          this.operation.amount = null;

          if (this.terms.length === 0) {
            this.showErrorLoadTerms();
          }

          if (this.terms.length === 1) {
            this.operation.price = this.terms[0].value;
            this.operation.amount = this.$options.filters.currency(
              this.operation.price.amount + this.getPercentageDeclared
            );
          }
        }
      } catch (error) {
        console.error(error);
        this.terms = [];
        this.isLoading = false;
        this.showErrorLoadTerms();
      }
    },

    /**
     *
     */
    showErrorLoadTerms() {
      this.errorPrice = true;
      setTimeout(() => {
        this.errorPrice = false;
      }, 10000);
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: `No se pudo carga el precio del la operacion`,
          text: `No hay un precio asociado a peso y codigo postal`,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },

    /**
     *
     */
    selectPrice() {
      if (this.operation.price) {
        this.operation.amount = this.$options.filters.currency(
          this.operation.price.amount + this.getPercentageDeclared
        );
      }
    },

    /**
     *
     */
    getPostalCode() {
      if (this.client.retirement === "client & point") {
        if (this.operation.retirement === "client") {
          return this.operation.retirement_item
            ? this.operation.retirement_item.postal_code
            : null;
        }
        if (this.operation.retirement === "point") {
          return this.operation.collection_point
            ? this.operation.collection_point.postal_code
            : null;
        }
      } else if (this.client.retirement === "client") {
        return this.operation.retirement_item
          ? this.operation.retirement_item.postal_code
          : null;
      } else if (this.client.retirement === "point") {
        return this.operation.collection_point
          ? this.operation.collection_point.postal_code
          : null;
      }
      // Toas no hay precios con estos datos
      return null;
    },

    /**
     *
     */
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },

    /**
     *
     */
    onPay() {
      const transactions = [this.item.id];
      this.$swal.queue([
        {
          title: "Pagar operaciones",
          confirmButtonText: "Pagar",
          text:
            "¿Estas seguro de que quieres pagar la operacion(es)" +
            transactions +
            "?",
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return MercadoPagoService.store({ transactions })
              .then((response) => {
                const { data } = response.data;
                this.$router.push("/dashboard/operations/pay/" + data.code);
              })
              .catch((error) => {
                console.error(error);
                this.$swal.insertQueueStep({
                  icon: "error",
                  title: "Error al intentar generar el pago",
                });
              });
          },
        },
      ]);
    },

    /**
     *
     */
    onClear() {
      this.$router.go(this.$router.currentRoute);
    },

    /**
     *
     */
    onSubmit(event) {
      event.preventDefault();
      this.$refs.submitButton.disabled = true;
      this.$refs.operationForm.validate().then((valid) => {
        if (valid) {
          const data = {
            id: this.operation.id,
            customer_id: this.client.id,
            dimensions: this.operation.dimensions,
            note: this.operation.note,
            term: this.operation.price.term,
            is_secured: this.operation.is_secured
              ? this.operation.is_secured
              : 0,
            declared_value: this.operation.is_secured
              ? this.operation.declared_value
              : 0,
            product: this.operation.product,
            recipient_last_name: this.operation.recipient_last_name,
            recipient_name: this.operation.recipient_name,
            recipient_email: this.operation.recipient_email,
            recipient_phone: this.operation.recipient_phone,
            retirement: this.operation.retirement,
            retirement_item: this.operation.retirement_item,
            retirement_point_id: this.operation.collection_point
              ? this.operation.collection_point.id
              : null,
            unit: this.operation.unit,
            weight: this.operation.weight,
            quantity_packages: this.operation.quantity_packages,
          };
          OperationService.store(data, {
            duplicate: this.duplicate,
          })
            .then(({ data }) => {
              this.formDisabled = true;

              if (this.isAdmin) {
                return;
              }

              let options = {
                title: "Operación exitosa",
                showCancelButton: true,
                confirmButtonText: "Crear otra operación",
                cancelButtonText: "Finalizar",
                reverseButtons: false,
                confirmButtonColor: "#f68d1e",
              };

              if (this.user.type === "pre-pago") {
                options = {
                  title:
                    "Para continuar con el proceso de envío, es necesario que la orden sea abonada.",
                  showCancelButton: true,
                  confirmButtonColor: AlertService.confirmButtonColor,
                  confirmButtonText: "Pagar",
                  cancelButtonText: "Crear otra operación",
                  reverseButtons: false,
                };
              }

              Swal.fire(options).then((result) => {
                if (result.value) {
                  if (this.user.type === "post-pago") {
                    this.onClear();
                    return;
                  }
                  const transactions = [data.data.id];
                  MercadoPagoService.store({ transactions })
                    .then((response) => {
                      const { data } = response.data;
                      this.$router.push(
                        "/dashboard/operations/pay/" + data.code
                      );
                    })
                    .catch((error) => {
                      console.error(error);
                      this.$swal.insertQueueStep({
                        icon: "error",
                        title: "Error al intentar generar el pago",
                      });
                    });
                } else if (this.user.type === "pre-pago") {
                  this.onClear();
                  return;
                }
                this.$refs.submitButton.disabled = false;
              });
            })
            .catch((error) => {
              console.error(error);
              this.$refs.submitButton.disabled = false;
              const response = error.response;
              if (response.status === 422) {
                const keys = Object.keys(response.data.errors);
                keys.forEach((key) => {
                  response.data.errors[key].forEach((error) => {
                    this.veeErrors.add({
                      field: key,
                      msg: error,
                    });
                  });
                });
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Actualizar`,
                    text: `Error al tratar de almacenar este elemento`,
                    icon: "AlertOctagonIcon",
                    variant: "danger",
                  },
                });
                return;
              } else if (response.status === 403) {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Agregar Nuevo`,
                    text: `No puedes realizar esta operación`,
                    icon: "AlertTriangleIcon",
                    variant: "warning",
                  },
                });
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Agregar Nuevo`,
                    text: `Error al tratar de almacenar este elemento`,
                    icon: "AlertOctagonIcon",
                    variant: "danger",
                  },
                });
              }
            });
          return;
        }

        setTimeout(() => {
          this.$refs.submitButton.disabled = false;
        }, 1000);

        /**
         * por defecto
         */
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Actualizar`,
            text: `El formulario contiene errores`,
            icon: "AlertOctagonIcon",
            variant: "danger",
          },
        });
      });
    },

    /**
     *
     */
    async loadAll() {
      await this.loadDeposit();
      await this.loadProvince();
      await this.loadPoints();
    },
  },
  mounted() {
    SettingService.declaredPercentage()
      .then(({ data }) => {
        this.percentageDeclared = data.percentageDeclared;
        this.baseValue = data.baseValue;
        this.maxValue = data.maxValue;
        this.contract = data.pdf;
      })
      .catch((error) => {
        this.isLoading = false;
        console.error(error);
      });

    if (this.client) {
      this.client.fullName = `${this.client.dni} ${this.client.name} ${this.client.last_name}`;
      this.operation.collection = this.operation.collection
        ? this.operation.collection
        : this.client.collection;
      this.operation.retirement = this.operation.retirement
        ? this.operation.retirement
        : this.client.retirement;
    }
    this.isLoading = true;
    this.loadAll()
      .then(() => {
        if (this.operation.retirement === "point") {
          this.operation.collection_point = this.points.find(
            (point) => point.id === this.operation.retirement_addresses.id
          );
        }
        this.operation.note = this.operation.notes;

        this.operation.price = {
          term: this.operation.term,
          amount: this.operation.amount - (this.operation.declared_amount || 0),
        };
        this.operation.unit = "KG";
        this.isLoading = false;
        this.showForm = true;
      })
      .catch((error) => {
        console.log(error);
        this.isLoading = false;
      });
  },
};
</script>

<style lang="scss" scoped>
.collection {
  background: #f0f3f5 !important;
}

.help-input {
  margin-top: 10px;
  color: gray;
  display: block;
}
</style>
